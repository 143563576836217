//import axios from 'src/utils/axios';
import axios from '../utils/axios';
import GAException from 'src/components/GAException';
import { prepaidcard_service_api_url as API_URL } from 'src/services/backendApiService';
import transactionService from 'src/services/transactionService';
import authService from './authService';

//const axios_dummy = false;
let BODY_HEADER = {}

const direct_api_tar = true;

if (direct_api_tar)
{
  // API TARJETA HABIENTES DIRECTAMENTE
  BODY_HEADER = {
    idSesion: "102",
    idRequest: "123456",
    codigoEF: "csoto",
    canal: "102",
    idClienteEF: "prueba",
    ciCliente: "csoto"
  }
}
else
{
  // BACKEND DE DIMO
  BODY_HEADER = {
    appVersion:"1.0.2",
    token:"prueba",
    user:"csoto"
  }
}

const CONFIG = {
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  }
};

export const toPrepaidCardData = (item) => {
  let data = {
    "DOCUMENTO": item.hasOwnProperty("DOCUMENTO") ? item.DOCUMENTO : "",
    "PROCESADOR": item.hasOwnProperty("PROCESADOR") ? item.PROCESADOR : "",
    "DESC_PROCESADOR": item.hasOwnProperty("DESC_PROCESADOR") ? item.DESC_PROCESADOR : "",
    "EMISOR": item.hasOwnProperty("EMISOR") ? item.EMISOR : "",
    "DESC_EMISOR": item.hasOwnProperty("DESC_EMISOR") ? item.DESC_EMISOR : "",
    "NUMERO_CUENTA": item.hasOwnProperty("NUMERO_CUENTA") ? item.NUMERO_CUENTA : "",
    "NUMERO_TARJETA": item.hasOwnProperty("NUMERO_TARJETA") ? item.NUMERO_TARJETA : "",
    "TAR_MASK": item.hasOwnProperty("TAR_MASK") ? item.TAR_MASK : "",
    "TIPO_MEDIO": item.hasOwnProperty("TIPO_MEDIO") ? item.TIPO_MEDIO : "",
    "NOMBRE_USUARIO": item.hasOwnProperty("NOMBRE_USUARIO") ? item.NOMBRE_USUARIO : "",
    "COMPONENTE": item.hasOwnProperty("COMPONENTE") ? item.COMPONENTE : "",
    "TIPO_TARJETA": item.hasOwnProperty("TIPO_TARJETA") ? item.TIPO_TARJETA : "",
    "TJ_PRINCIPAL": item.hasOwnProperty("TJ_PRINCIPAL") ? item.TJ_PRINCIPAL : "",
    "FECHA_OTORGADA": item.hasOwnProperty("FECHA_OTORGADA") ? item.FECHA_OTORGADA : "",
    "ADICIONAL": item.hasOwnProperty("ADICIONAL") ? item.ADICIONAL : "",
    "FECHA_VIGENCIA": item.hasOwnProperty("FECHA_VIGENCIA") ? item.FECHA_VIGENCIA : "",
    "VENCIMIENTO_TAR": item.hasOwnProperty("VENCIMIENTO_TAR") ? item.VENCIMIENTO_TAR : "",
    "ESTADO": item.hasOwnProperty("ESTADO") ? item.ESTADO : "",
    "DESC_ESTADO": item.hasOwnProperty("DESC_ESTADO") ? item.DESC_ESTADO : "",
    "ULTIMO_PERIODO_CERRADO": item.hasOwnProperty("ULTIMO_PERIODO_CERRADO") ? item.ULTIMO_PERIODO_CERRADO : "",
    "DISPONIBLE_NORMAL": item.hasOwnProperty("DISPONIBLE_NORMAL") ? item.DISPONIBLE_NORMAL : "",
    "PERIODO_ACTUAL_TARJETA": item.hasOwnProperty("PERIODO_ACTUAL_TARJETA") ? item.PERIODO_ACTUAL_TARJETA : "",
    "FLG_RENOVACION": item.hasOwnProperty("FLG_RENOVACION") ? item.FLG_RENOVACION : "",
    "ES_DIMO": item.hasOwnProperty("ES_DIMO") ? item.ES_DIMO : "",
    "CIERRE": item.hasOwnProperty("CIERRE") ? item.CIERRE : "",
    "TARJETA": item.hasOwnProperty("TARJETA") ? item.TARJETA : "",
    "ID_TIPO_TARJETA_PREPAGA": item.hasOwnProperty("ID_TIPO_TARJETA_PREPAGA") ? item.ID_TIPO_TARJETA_PREPAGA : "",
    "TIPO_TARJETA_PREPAGA": item.hasOwnProperty("TIPO_TARJETA_PREPAGA") ? item.TIPO_TARJETA_PREPAGA : "",
    "ESTADO_TARJETA_FISICA": item.hasOwnProperty("ESTADO_TARJETA_FISICA") ? item.ESTADO_TARJETA_FISICA : "",
    "DESC_ESTADO_TARJETA_FISICA": item.hasOwnProperty("DESC_ESTADO_TARJETA_FISICA") ? item.DESC_ESTADO_TARJETA_FISICA : "",
    "ID_GRUPO_AFINIDAD": item.hasOwnProperty("ID_GRUPO_AFINIDAD") ? item.ID_GRUPO_AFINIDAD : "",

    // Dato adicional que no viene en el API
    "NUMERO_TARJETA_FORMATEADO": getNumeroTarjetaFormateado(item.hasOwnProperty("NUMERO_TARJETA") ? item.NUMERO_TARJETA : "")
  }

  return data;
}

export const toFacturaDimoData = (itemData) => {
  const item = itemData ? itemData : {};
  const data = {
    "ANIO": item.hasOwnProperty("ANIO") ? item.ANIO : "",
    "MES": item.hasOwnProperty("MES") ? item.MES : "",
    "PROCESADOR": item.hasOwnProperty("PROCESADOR") ? item.PROCESADOR : "",
    "EMISOR": item.hasOwnProperty("EMISOR") ? item.EMISOR : "",
    "FECHA_EMISION": item.hasOwnProperty("FECHA_EMISION") ? item.FECHA_EMISION : "",
    "FLAG_FACTURA": item.hasOwnProperty("FLAG_FACTURA") ? item.FLAG_FACTURA : "",
    "FACTURA": item.hasOwnProperty("FACTURA") ? item.FACTURA : "",
    "MONTO_FACTURA": item.hasOwnProperty("MONTO_FACTURA") ? item.MONTO_FACTURA : "",
    "IVA": item.hasOwnProperty("IVA") ? item.IVA : "",
    "NOMBRE_RAZON_SOCIAL": item.hasOwnProperty("NOMBRE_RAZON_SOCIAL") ? item.NOMBRE_RAZON_SOCIAL : "",
    "DOCUMENTO": item.hasOwnProperty("DOCUMENTO") ? item.DOCUMENTO : "",
  }

  return data;
}

export const getNumeroTarjetaFormateado=(numeroTar)=>{
  let tarjFormateado = numeroTar+"";

  if (tarjFormateado.trim().length === 16) {
    tarjFormateado = tarjFormateado.substr(0,4) + '\u00A0\u00A0' +
                     tarjFormateado.substr(4,4) + '\u00A0\u00A0' +
                     tarjFormateado.substr(8,4) + '\u00A0\u00A0' +
                     tarjFormateado.substr(12,4);
  }

  return tarjFormateado;
}

export const toCostoOperacionDimoData=(itemData)=>{
  const item = itemData ? itemData : {};

  const data = {
    "COSTO_ID": item.hasOwnProperty("COSTO_ID") ? (item.COSTO_ID+"") : "",
    "COSTO_DESCRIPCION": item.hasOwnProperty("COSTO_DESCRIPCION") ? (item.COSTO_DESCRIPCION+"") : "",
    "MONTO_DESDE": item.hasOwnProperty("MONTO_DESDE") ? (item.MONTO_DESDE+"") : "",
    "MONTO_HASTA": item.hasOwnProperty("MONTO_HASTA") ? (item.MONTO_HASTA+"") : "",
    "TIPO_CARGO_ORIGINANTE": item.hasOwnProperty("TIPO_CARGO_ORIGINANTE") ? (item.TIPO_CARGO_ORIGINANTE+"") : "",
    "IMPORTE_CARGO_ORIGINANTE": item.hasOwnProperty("IMPORTE_CARGO_ORIGINANTE") ? (item.IMPORTE_CARGO_ORIGINANTE+"") : "",
    "TASA_CARGO_ORIGINANTE": item.hasOwnProperty("TASA_CARGO_ORIGINANTE") ? (item.TASA_CARGO_ORIGINANTE+"") : "",
    "TIPO_CARGO_DESTINATARIO": item.hasOwnProperty("TIPO_CARGO_DESTINATARIO") ? (item.TIPO_CARGO_DESTINATARIO+"") : "",
    "IMPORTE_CARGO_DESTINATARIO": item.hasOwnProperty("IMPORTE_CARGO_DESTINATARIO") ? (item.IMPORTE_CARGO_DESTINATARIO+"") : "",
    "TASA_CARGO_DESTINATARIO": item.hasOwnProperty("TASA_CARGO_DESTINATARIO") ? (item.TASA_CARGO_DESTINATARIO+"") : "",
    "APLICAR_IVA": item.hasOwnProperty("APLICAR_IVA") ? (item.APLICAR_IVA+"") : "",
    "CANAL": item.hasOwnProperty("CANAL") ? (item.CANAL+"") : "",
    "PAGADOR": item.hasOwnProperty("PAGADOR") ? (item.PAGADOR+"") : "",
    "TIPO_TRANSACCION": item.hasOwnProperty("TIPO_TRANSACCION") ? (item.TIPO_TRANSACCION+"") : "",
    "COD_SERVICIO": item.hasOwnProperty("COD_SERVICIO") ? (item.COD_SERVICIO+"") : "",
    "DESCRIPCION_SERVICIO": item.hasOwnProperty("DESCRIPCION_SERVICIO") ? (item.DESCRIPCION_SERVICIO+"") : "",
    "EF_ORIGINANTE": item.hasOwnProperty("EF_ORIGINANTE") ? (item.EF_ORIGINANTE+"") : "",
    "EMISOR_CABAL_ORIGINANTE": item.hasOwnProperty("EMISOR_CABAL_ORIGINANTE") ? (item.EMISOR_CABAL_ORIGINANTE+"") : "",
    "DESC_EMI_CABAL_ORIGINANTE": item.hasOwnProperty("DESC_EMI_CABAL_ORIGINANTE") ? (item.DESC_EMI_CABAL_ORIGINANTE+"") : "",
    "TIPO_CUENTA_ORIGEN": item.hasOwnProperty("TIPO_CUENTA_ORIGEN") ? (item.TIPO_CUENTA_ORIGEN+"") : "",
    "DESC_TIPO_CUENTA_ORIGEN": item.hasOwnProperty("DESC_TIPO_CUENTA_ORIGEN") ? (item.DESC_TIPO_CUENTA_ORIGEN+"") : "",
    "EF_DESTINATARIO": item.hasOwnProperty("EF_DESTINATARIO") ? (item.EF_DESTINATARIO+"") : "",
    "EMISOR_CABAL_DESTINATARIO": item.hasOwnProperty("EMISOR_CABAL_DESTINATARIO") ? (item.EMISOR_CABAL_DESTINATARIO+"") : "",
    "DESC_EMI_CABAL_DESTINATARIO": item.hasOwnProperty("DESC_EMI_CABAL_DESTINATARIO") ? (item.DESC_EMI_CABAL_DESTINATARIO+"") : "",
    "TIPO_CUENTA_DESTINO": item.hasOwnProperty("TIPO_CUENTA_DESTINO") ? (item.TIPO_CUENTA_DESTINO+"") : "",
    "DESC_TIPO_CUENTA_DESTINO": item.hasOwnProperty("DESC_TIPO_CUENTA_DESTINO") ? (item.DESC_TIPO_CUENTA_DESTINO+"") : "",
    "FECHA_VIGENCIA_DESDE": item.hasOwnProperty("FECHA_VIGENCIA_DESDE") ? (item.FECHA_VIGENCIA_DESDE+"") : "",
    "FECHA_VIGENCIA_HASTA": item.hasOwnProperty("FECHA_VIGENCIA_HASTA") ? (item.FECHA_VIGENCIA_HASTA+"") : "",
  }

  return data;
}

class PrepaidCardService {
  /*setAxiosInterceptors = ({ onLogout }) => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          this.setSession(null);

          if (onLogout) {
            onLogout();
          }
        }

        return Promise.reject(error);
      }
    );
  };*/

  avisoViajeDimo = (AvisoViajeDimo) => new Promise((resolve, reject) => {
    axios.post(API_URL + '/v1/AvisoViajeDimo', { header: BODY_HEADER, data: AvisoViajeDimo })
      .then((response) => {
        if (response.data.header.codReturn === '0') {
          resolve(response.data.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
                      //GAException(error);
        reject(error);
      });
  })

  bloqueoDesbloqueoDimo = (bloqueoDesbloqueoDimo) => new Promise((resolve, reject) => {
    axios.post(API_URL + '/v1/BloqueoDesbloqueoDimo', { header: BODY_HEADER, data: bloqueoDesbloqueoDimo })
      .then((response) => {
        if (response.data.header.codReturn === '0') {
          resolve(response.data.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
                      //GAException(error);
        reject(error);
      });
  })

  cargoAplicadoDimo = (CargoAplicadoDimo) => new Promise((resolve, reject) => {
    axios.post(API_URL + '/v1/CargoAplicadoDimo', { header: BODY_HEADER, data: CargoAplicadoDimo })
      .then((response) => {
        if (response.data.header.codReturn === '0') {
          resolve(response.data.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
                      //GAException(error);
        reject(error);
      });
  })

  clienteDimo = (ClienteDimo) => new Promise((resolve, reject) => {
    axios.post(API_URL + '/v1/ClienteDimo', { header: BODY_HEADER, data: ClienteDimo })
      .then((response) => {
        if (response.data.header.codReturn === '0') {
          resolve(response.data.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
                      //GAException(error);
        reject(error);
      });
  })

  activarPlasticoDimo = (ActivarPlasticoDimo) => new Promise((resolve, reject) => {
    //console.log("prmAct", ActivarPlasticoDimo);
    axios.post(API_URL + '/v1/ActivarPlasticoDimo', { header: BODY_HEADER, data: ActivarPlasticoDimo })
      .then((response) => {
        if (response.data.header.codReturn === '0') {
          resolve(response.data.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
                      //GAException(error);
        reject(error);
      });
  })

  // @deprecado
  consultarPinDimo = (ConsultarPinDimo) => new Promise((resolve, reject) => {
    axios.post(API_URL + '/v1/ConsultarPinDimo', { header: BODY_HEADER, data: ConsultarPinDimo })
      .then((response) => {
        if (response.data.header.codReturn === '0') {
          resolve(response.data.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
                      //GAException(error);
        reject(error);
      });
  })

  consultaSaldoDimo = (ConsultaSaldoDimo) => new Promise((resolve, reject) => {
    axios.post(API_URL + '/v1/ConsultaSaldoDimo', { header: BODY_HEADER, data: ConsultaSaldoDimo })
      .then((response) => {
        if (response.data.header.codReturn === '0') {
          resolve(response.data.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
                      //GAException(error);
        reject(error);
      });
  })

  costosOperacionalesDimo = (CostosOperacionalesDimo) => new Promise((resolve, reject) => {
    axios.post(API_URL + '/v1/CostosOperacionalesDimo', { header: BODY_HEADER, data: CostosOperacionalesDimo })
      .then((response) => {
        if (response.data.header.codReturn === '0') {
          resolve(response.data.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
                      //GAException(error);
        reject(error);
      });
  })

  descargarEstadoCuenta = (DescargarEstadoCuenta) => new Promise((resolve, reject) => {
    axios.post(API_URL + '/v1/DescargarEstadoCuenta', { header: BODY_HEADER, data: DescargarEstadoCuenta })
      .then((response) => {
        if (response.data.header.codReturn === '0') {
          resolve(response.data.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
                      //GAException(error);
        reject(error);
      });
  })

  estadoCuentaPorPeriodo = (EstadoCuentaPorPeriodo) => new Promise((resolve, reject) => {
    axios.post(API_URL + '/v1/EstadoCuentaPorPeriodo', { header: BODY_HEADER, data: EstadoCuentaPorPeriodo })
      .then((response) => {
        if (response.data.header.codReturn === '0') {
          resolve(response.data.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
                      //GAException(error);
        reject(error);
      });
  })

  generacionPinDimo = (GeneracionPinDimo) => new Promise((resolve, reject) => {
    axios.post(API_URL + '/v1/GeneracionPinDimo', { header: BODY_HEADER, data: GeneracionPinDimo })
      .then((response) => {
        if (response.data.header.codReturn === '0') {
          resolve(response.data.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
                      //GAException(error);
        reject(error);
      });
  })

  limitesTransaccionesDimo = (LimitesTransaccionesDimo) => new Promise((resolve, reject) => {
    axios.post(API_URL + '/v1/LimitesTransaccionesDimo', { header: BODY_HEADER, data: LimitesTransaccionesDimo })
      .then((response) => {
        if (response.data.header.codReturn === '0') {
          resolve(response.data.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
                      //GAException(error);
        reject(error);
      });
  })

  marcarLimiteMensualTarjeta = (MarcarLimiteMensualTarjeta) => new Promise((resolve, reject) => {
    axios.post(API_URL + '/v1/MarcarLimiteMensualTarjeta', { header: BODY_HEADER, data: MarcarLimiteMensualTarjeta })
      .then((response) => {
        if (response.data.header.codReturn === '0') {
          resolve(response.data.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
                      //GAException(error);
        reject(error);
      });
  })

  modificacionPinDimo = (ModificacionPinDimo) => new Promise((resolve, reject) => {
    axios.post(API_URL + '/v1/ModificacionPinDimo', { header: BODY_HEADER, data: ModificacionPinDimo })
      .then((response) => {
        if (response.data.header.codReturn === '0') {
          resolve(response.data.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
                      //GAException(error);
        reject(error);
      });
  })

  movimientosDimo = (MovimientosDimo) => new Promise((resolve, reject) => {
    axios.post(API_URL + '/v1/MovimientosDimo', { header: BODY_HEADER, data: MovimientosDimo })
      .then((response) => {
        if (response.data.header.codReturn === '0') {
          resolve(response.data.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
                      //GAException(error);
        reject(error);
      });
  })

  pedidoImpresionTarjetaDimo = (PedidoImpresionTarjetaDimo) => new Promise((resolve, reject) => {
    axios.post(API_URL + '/v1/PedidoImpresionTarjetaDimo', { header: BODY_HEADER, data: PedidoImpresionTarjetaDimo })
      .then((response) => {
        if (response.data.header.codReturn === '0') {
          resolve(response.data.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
                      //GAException(error);
        reject(error);
      });
  })

  registroDimoPreemitida = (RegistroDimoPreemitida) => new Promise((resolve, reject) => {
    axios.post(API_URL + '/v1/RegistroDimoPreemitida', { header: BODY_HEADER, data: RegistroDimoPreemitida })
      .then((response) => {
        if (response.data.header.codReturn === '0') {
          resolve(response.data.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
                      //GAException(error);
        reject(error);
      });
  })

  registroDimo = (RegistroDimo) => new Promise((resolve, reject) => {
    axios.post(API_URL + '/v1/RegistroDimo', { header: BODY_HEADER, data: RegistroDimo })
      .then((response) => {
        if (response.data.header.codReturn === '0') {
          resolve(response.data.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
                      //GAException(error);
        reject(error);
      });
  })

  registroRelacionarDimoPreemitida = (RegistroRelacionarDimoPreemitida) => new Promise((resolve, reject) => {
    axios.post(API_URL + '/v1/RegistroRelacionarDimoPreemitida', { header: BODY_HEADER, data: RegistroRelacionarDimoPreemitida })
      .then((response) => {
        if (response.data.header.codReturn === '0') {
          resolve(response.data.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
                      //GAException(error);
        reject(error);
      });
  })

  reposicionTarjetaDimo = (ReposicionTarjetaDimo) => new Promise((resolve, reject) => {
    axios.post(API_URL + '/v1/ReposicionTarjetaDimo', { header: BODY_HEADER, data: ReposicionTarjetaDimo })
      .then((response) => {
        if (response.data.header.codReturn === '0') {
          resolve(response.data.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
                      //GAException(error);
        reject(error);
      });
  })

  seguimientoImpresionTarjetaDimo = (SeguimientoImpresionTarjetaDimo) => new Promise((resolve, reject) => {
    axios.post(API_URL + '/v1/SeguimientoImpresionTarjetaDimo', { header: BODY_HEADER, data: SeguimientoImpresionTarjetaDimo })
      .then((response) => {
        if (response.data.header.codReturn === '0') {
          resolve(response.data.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
                      //GAException(error);
        reject(error);
      });
  })

  seguimientoReposicionDimo = (SeguimientoReposicionDimo) => new Promise((resolve, reject) => {
    axios.post(API_URL + '/v1/SeguimientoReposicionDimo', { header: BODY_HEADER, data: SeguimientoReposicionDimo })
      .then((response) => {
        if (response.data.header.codReturn === '0') {
          resolve(response.data.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
                      //GAException(error);
        reject(error);
      });
  })

  getDimoData = () => {
    return JSON.parse(localStorage.getItem('tpdimo'));
  }

  tarjetasDimo = (TarjetasDimo, noValidaFlagVerificadoATC) => new Promise((resolve, reject) => {
    const getTarjetasDimo = () =>
    axios.post(
      API_URL + '/v1/TarjetasDimo',
      {
        header: BODY_HEADER,
        data: TarjetasDimo,
      },
      CONFIG
    )
    .then((response) =>
    {
      if (response.data.header.codReturn === '0') {
        localStorage.setItem('tpdimo', JSON.stringify(response.data.data[0]));
        resolve(response.data.data);
      } else {
        reject({ error: { message: response.data.header.txtReturn } });
      }
    })
    .catch((error) => {
                    //GAException(error);
      reject(error);
    });


    const _noValidaFlagVerificadoATC = noValidaFlagVerificadoATC && noValidaFlagVerificadoATC === true ? true : false;

    // Si no debemos validar el flag de "Usuario Verificado por ATC"
    if (_noValidaFlagVerificadoATC) {
      //Retornamos las TP normalmente
      getTarjetasDimo();
    }
    else {
      // Si debemos validar, buscamos la marca entre los atributos del usuario...
      if (authService.isUserValidatedATC()) {
        getTarjetasDimo();
      }
      else {
        // Si no esta valilado por ATC, consultamos al Backend si sigue estando no validado
        authService.datosSesionUsuario({})
        .then(()=> {
          // Si ya esta validado
          if (authService.isUserValidatedATC()) {
            // Obtenemos las tarjetas DIMO
            getTarjetasDimo();
          } else {
            // No retornamos ninguna TP
            resolve([]);
          }
        })
        .catch((error)=>{
          reject(error);
        })
      }

    }
  })

  getTPDIMO = () => {
    return JSON.parse(localStorage.getItem('tpdimo') === "undefined" || localStorage.getItem('tpdimo') === null ? "{}" : localStorage.getItem('tpdimo') );
  };

  cabalBenefits = (cabalBenefits) => new Promise((resolve, reject) => {
    axios.post(
      API_URL + '/v1/AcreditarDimo',
      {
        header: BODY_HEADER,
        data: {
          numeroTarjeta: cabalBenefits.numeroTarjeta,
          operacion: cabalBenefits.operacion,
        },
      },
      CONFIG
    )
      .then((response) => {
        if (response.data.header.codReturn === '0' && response.data.data.APLICAR === '1') {
          this.cabalBenefitsTransaction({
            IMPORTE: cabalBenefits.IMPORTE,
            NUMERO_TARJETA: cabalBenefits.numeroTarjeta,
            ciDestinatario: cabalBenefits.ciDestinatario,
            titularDestinatario: cabalBenefits.titularDestinatario,
          });
          resolve(response.data.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
                      //GAException(error);
        reject(error);
      });
  });

  cabalBenefitsTransaction = (cabalBenefitsTransaction) => {
    transactionService.cabalBenefitsTransaction(cabalBenefitsTransaction)
      .then((responseTrx) => {
        if (responseTrx.data.header.codReturn === '0') {
          this.cabalBenefits({
            cabalBenefits: {
              numeroTarjeta: cabalBenefitsTransaction.NUMERO_TARJETA,
              operacion: '0',
            }
          })
        }
      })
      .catch((error) => {
                      //GAException(error);
        throw error;
      });
  }

  costosTransaccionales = (costos) => new Promise((resolve, reject) => {
    axios.post(API_URL + '/v1/CostosOperacionalesDimo', { header: BODY_HEADER, data: costos })
      .then((response) => {
        if (response.data.header.codReturn === '0') {
          resolve(response.data.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
                      //GAException(error);
        reject(error);
      });
  });


  consultaFacturaDimo = (data) => new Promise((resolve, reject) => {
    const dataPrm = {
      numeroCuenta: data.hasOwnProperty("numeroCuenta") ? data.numeroCuenta : "",
      periodo: data.hasOwnProperty("periodo") ? data.periodo : "",
      periodoActual: data.hasOwnProperty("periodoActual") ? data.periodoActual : "",
    }
    axios.post(API_URL + '/v1/ConsultaFacturaDimo', { header: BODY_HEADER, data: dataPrm })
      .then((response) => {
        if (response.data.header.codReturn === '0') {
          resolve(response.data.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
                      //GAException(error);
        reject(error);
      });
  })

}

const prepaidCardService = new PrepaidCardService();

export default prepaidCardService;
